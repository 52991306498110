<template>
  <div class="form-memkyc-detail">
    <ValidationObserver ref="form" @change="isFormChange = true" v-slot="{ handleSubmit }">
      <CForm @submit.prevent="handleSubmit(onSubmit)">
        <CRow class="wrap-kycs" v-if="accountType.value === 'Individual' || accountType.value === 'Company'">
          <CCol md="8">
            <CRow>
              <CCol lg="2" md="2">
                <p class="text-bold individual">{{ $t('MEMBER_DETAIL_COMPONENT_LABEL_LEVEL') + ' ' + kycLevel }}</p>
              </CCol>
              <CCol lg="10" md="10">
                <div v-if="accountType.value === 'Individual'">
                  <img class="imgClass" src="@/assets/images/Shape.svg" />
                  <span class="text-bold individual">{{ $t(`MEMBER_ACCOUNT_TYPE_${accountType.value}`.toUpperCase()) }}</span>
                </div>
                <div v-else>
                  <img class="imgClass" src="@/assets/images/icon_company.svg" />
                  <span class="text-bold company">{{ $t(`MEMBER_ACCOUNT_TYPE_${accountType.value}`.toUpperCase()) }}</span>
                </div>
              </CCol>

              <CCol v-if="memberKycDetail.last_name" lg="6" md="6" class="form-group">
                <label class="col-form-label">{{ $t(memberKycDetail.last_name.textKey) }}</label>
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t(`MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_${memberKycDetail.last_name.field_key.toUpperCase()}`)"
                  :rules="getRules(memberKycDetail.last_name)"
                >
                  <input type="text" :disabled="getDisable()" v-model="memberKycDetail.last_name.value" class="form-control text-bold" />
                  <span class="error-msg mt-1">{{ errors[0] }}</span>
                </ValidationProvider>
              </CCol>

              <CCol v-if="memberKycDetail.first_name" lg="6" md="6" class="form-group">
                <label class="col-form-label">{{ $t(memberKycDetail.first_name.textKey) }}</label>
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t(`MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_${memberKycDetail.first_name.field_key.toUpperCase()}`)"
                  :rules="getRules(memberKycDetail.first_name)"
                >
                  <input type="text" :disabled="getDisable()" v-model="memberKycDetail.first_name.value" class="form-control text-bold" />
                  <span class="error-msg mt-1">{{ errors[0] }}</span>
                </ValidationProvider>
              </CCol>

              <CCol v-if="memberKycDetail.last_name_kanji" lg="6" md="6" class="form-group">
                <label class="col-form-label">{{ $t(memberKycDetail.last_name_kanji.textKey) }}</label>
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t(`MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_${memberKycDetail.last_name_kanji.field_key.toUpperCase()}`)"
                  :rules="getRules(memberKycDetail.last_name_kanji)"
                >
                  <input
                    type="text"
                    :disabled="getDisable()"
                    v-model="memberKycDetail.last_name_kanji.value"
                    class="form-control text-bold"
                  />
                  <span class="error-msg mt-1">{{ errors[0] }}</span>
                </ValidationProvider>
              </CCol>

              <CCol v-if="memberKycDetail.first_name_kanji" lg="6" md="6" class="form-group">
                <label class="col-form-label">{{ $t(memberKycDetail.first_name_kanji.textKey) }}</label>
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t(`MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_${memberKycDetail.first_name_kanji.field_key.toUpperCase()}`)"
                  :rules="getRules(memberKycDetail.first_name_kanji)"
                >
                  <input
                    type="text"
                    :disabled="getDisable()"
                    v-model="memberKycDetail.first_name_kanji.value"
                    class="form-control text-bold"
                  />
                  <span class="error-msg mt-1">{{ errors[0] }}</span>
                </ValidationProvider>
              </CCol>

              <CCol v-if="memberKycDetail.date_of_birth" lg="6" md="6" class="form-group">
                <label class="col-form-label">{{ $t(memberKycDetail.date_of_birth.textKey) }}</label>
                <div class="input-group date-time-input">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t(`MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_${memberKycDetail.date_of_birth.field_key.toUpperCase()}`)"
                    :rules="getRules(memberKycDetail)"
                  >
                    <div class="date-time-inline">
                      <datetime
                        :disabled="getDisable()"
                        type="datetime"
                        v-model="memberKycDetail.date_of_birth.value"
                        :format="dateTimePickerFormat"
                        input-class="form-control"
                      ></datetime>
                      <div class="input-group-append">
                        <span v-if="!getDisable()" class="input-group-text" @click="clearFromDate()">
                          <i class="fas fa-times"></i>
                        </span>
                      </div>
                    </div>
                    <span class="error-msg mt-1">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </CCol>

              <CCol v-if="memberKycDetail.company_name && accountType.value !== 'Individual'" lg="12" md="12" class="mt-3 mb-3">
                {{ $t(`MEMBER_DETAIL_COMPONENT_KYC_COMPANY_INFO`) }}
              </CCol>
              <CCol v-if="memberKycDetail.company_name && accountType.value !== 'Individual'" lg="6" md="6" class="form-group">
                <label class="col-form-label">{{ $t(memberKycDetail.company_name.textKey) }}</label>
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t(`MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_${memberKycDetail.company_name.field_key.toUpperCase()}`)"
                  :rules="getRules(memberKycDetail.company_name)"
                >
                  <input type="text" :disabled="getDisable()" v-model="memberKycDetail.company_name.value" class="form-control text-bold" />
                  <span class="error-msg mt-1">{{ errors[0] }}</span>
                </ValidationProvider>
              </CCol>
              <CCol lg="6" md="6" class="form-group">
                <div v-if="memberKycDetail.phone">
                  <label class="col-form-label">{{ $t(memberKycDetail.phone.textKey) }}</label>
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t(`MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_${memberKycDetail.phone.field_key.toUpperCase()}`)"
                    :rules="getRules(memberKycDetail.phone)"
                  >
                    <input type="text" :disabled="getDisable()" v-model="memberKycDetail.phone.value" class="form-control text-bold" />
                    <span class="error-msg mt-1">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </CCol>

              <CCol v-if="memberKycDetail.address" lg="12" md="12" class="form-group">
                <label class="col-form-label">{{ $t(memberKycDetail.address.textKey) }}</label>
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t(`MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_${memberKycDetail.address.field_key.toUpperCase()}`)"
                  :rules="getRules(memberKycDetail.address)"
                >
                  <input type="text" :disabled="getDisable()" v-model="memberKycDetail.address.value" class="form-control text-bold" />
                  <span class="error-msg mt-1">{{ errors[0] }}</span>
                </ValidationProvider>
              </CCol>

              <CCol v-if="memberKycDetail.country" lg="6" md="6" class="form-group">
                <label class="col-form-label">{{ $t(memberKycDetail.country.textKey) }}</label>
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t(`MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_${memberKycDetail.country.field_key.toUpperCase()}`)"
                  :rules="getRules(memberKycDetail.country)"
                >
                  <CSelect
                    class="country-drop"
                    :value.sync="memberKycDetail.country.value"
                    :options="listCountryOfKyc"
                    :disabled="getDisable()"
                  />
                  <span class="error-msg mt-1">{{ errors[0] }}</span>
                </ValidationProvider>
              </CCol>

              <CCol v-if="memberKycDetail.city" lg="6" md="6" class="form-group">
                <label class="col-form-label">{{ $t(memberKycDetail.city.textKey) }}</label>
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t(`MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_${memberKycDetail.city.field_key.toUpperCase()}`)"
                  :rules="getRules(memberKycDetail.city)"
                >
                <CSelect
                  class="country-drop"
                  v-if="memberKycDetail.country.value === 'JP'"
                  :options="listProvicesOfJp"
                  :disabled="getDisable()"
                  :value.sync="memberKycDetail.city.value"
                />
                <input v-else type="text" :disabled="getDisable()" v-model="memberKycDetail.city.value" class="form-control text-bold" />
                  <span class="error-msg mt-1">{{ errors[0] }}</span>
                </ValidationProvider>
              </CCol>
            </CRow>
          </CCol>
          <CCol md="4">
            <CRow>
              <CCol lg="10" md="10"> </CCol>
              <CCol lg="2" md="2" class="edit-area">
                <div v-if="enabledEditKyc" class="edit-member-btn" @click="toggleShowEdit()">
                  <img src="@/assets/images/ico-edit-user.svg" alt class="hand edit-icon" />
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol v-if="documentList.length > 0" lg="12" md="12" class="imgList">
                <ListImage :listImgs="documentList" />
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CForm>
    </ValidationObserver>
    <StakingModal :linkTo="linkTo" :warningLeavePage="warningLeavePage" @close-modal="closeModal" />
  </div>
</template>

<script>
import _ from 'lodash';
import StakingBadge from '@/components/StakingBadge';
import ListImage from '@/components/Member/ListImageUploadedComponent';
import StakingModal from '@/components/StakingModal/index';
import stringHelper from '@/utils/string-helper';
import responseHelper from '@/utils/response-helper';
import endpoints from '@/constants/endpoints';
import { COUNTRY } from '@/constants/countries';
import { STATE } from '@/constants/japanState';

const getRules = _.memoize((kycProperty) => {
  const rules = [];
  if (kycProperty.require_flg || kycProperty.field_key == "company_name") {
    rules.push('required');
  }

  if (kycProperty.max_length > 0) {
    rules.push(`max:${kycProperty.max_length}`);
  }

  if (kycProperty.data_type === 'PHONE') {
    rules.push('integer');
    rules.push('min_value:0');
  }

  return rules.join('|');
});

export default {
  name: 'MemberDetailKyc',
  data() {
    return {
      warningLeavePage: false,
      linkTo: '/admin/members',
      memberKycEdit: [],
      dateTimePickerFormat: 'yyyy-MM-dd',
      memberKyc: [],
      memberKycDetail: {},
      documentList: [],
      isIndividualAccount: true,
      kycProperties: [],
      propertiesCache: {},
      cacheImg: [],
      kycPropertiesTmp: [],
      listProperties: [],
      accountType: {},
      STATE,
      COUNTRY
    };
  },
  props: {
    memberId: {
      type: String,
      default: '',
    },
    kycLevel: {
      type: String,
      default: '',
    },
    isEnabledEditKycContent: {
      type: Boolean,
      default: false,
    },
    enabledEditKyc: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ListImage,
    StakingModal,
  },
  computed: {
    listProvicesOfJp(){
      return this.STATE.map(el => {
        return {
          label: this.$t(el),
          value: el
        };
      });
    },
    listCountryOfKyc(){
      return this.COUNTRY.map(el => {
        return {
          value: el.code,
          label: el.name
        };
      });
    }
  },
  async mounted() {
    const [memberKyc, kycProperties] = await Promise.all([this.getMemberKycs(), this.getKYCProperties()]);
    this.memberKyc = memberKyc || [];
    this.kycProperties = kycProperties || [];
    this.propertiesCache = this.kycProperties.reduce((result, item) => {
      result[item.id] = item;

      return result;
    }, {});
    this.getMemberKyc(this.memberKyc);
  },
  methods: {
    getRules(kycProperty) {
      return getRules(kycProperty);
    },
    getDisable() {
     return this.isEnabledEditKycContent ? false : true;
    },
    toggleShowEdit() {
      this.$emit('toggleShowEdit');
    },
    async resetForm() {
      if (this.isFormChange) {
        const result = await this.getMemberKycs();
        this.getMemberKyc(result);
      }
    },
    openWarningLeaveModal() {
      if (this.isFormChange) {
        this.warningLeavePage = true;
      } else {
        this.$router.push(this.linkTo);
      }
    },
    closeModal(val) {
      this.warningLeavePage = val;
    },
    async getMemberKycs() {
      try {
        const res = await this.$http.get(endpoints.getMemberKycs(this.memberId));
        return res.data;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MEMBER_DETAIL_COMPONENT_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getKYCProperties() {
      try {
        const res = await this.$http.get(endpoints.getKYCProperties);
        return res.data;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MEMBER_DETAIL_COMPONENT_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    getMemberKyc(memberKyc) {
      memberKyc.forEach((kycSection) => {
        let memberKycProperties = kycSection.MemberKycProperties || [];
        let accountTypeKycProperty = null;

        memberKycProperties = memberKycProperties.filter((el) => {
          if (el.field_key === 'account_type') {
            el.order_index = 0;
            el.value = el.value || 'Individual';
            accountTypeKycProperty = el;

            this.isIndividualAccount = el.value.toUpperCase() !== 'INDIVIDUAL';
            return false;
          }
          const isUploadFile = this.checkDocumentKey(el.field_key);
          el.isUploadFile = isUploadFile;

          return (isUploadFile && el.value) || !isUploadFile;
        });
        if (kycSection.kyc_level == 1) {
          if (accountTypeKycProperty) {
            accountTypeKycProperty.value = this.$t(`MEMBER_ACCOUNT_TYPE_${accountTypeKycProperty.value.toUpperCase()}`);
            accountTypeKycProperty.isIgnoredEditing = true;

            memberKycProperties.push(accountTypeKycProperty);
            this.accountType = accountTypeKycProperty;
          } else {
            const accountTypeOjb = {
              field_key: 'account_type',
              field_name: 'Account Type',
              value: 'Individual',
              order_index: 0,
              isIgnoredEditing: true,
            };
            memberKycProperties.push(accountTypeOjb);
            this.accountType = accountTypeOjb;
          }
        } else if (kycSection.kyc_level == 2) {
          memberKycProperties.forEach((item) => {
            if (item.isUploadFile && this.cacheImg.indexOf(item.id) === -1) {
              this.cacheImg.push(item.id);
              this.documentList.push(item);
            }
          });
        }

        memberKycProperties.forEach((item) => {
          const property = this.propertiesCache[item.property_id];
          if (property) {
            item.data_type = property.data_type;
            item.enabled_flg = property.enabled_flg;
            item.order_index = item.order_index || property.order_index;
            item.require_flg = property.require_flg;
            item.max_length = property.max_length;
          } else {
            item.order_index = _.isUndefined(item.order_index) ? 9999999 : item.order_index;
            item.data_type = item.data_type || 'TEXT';
          }

          if (item.field_key == 'phone') {
            item.data_type = 'PHONE';
          }
          item.textKey = stringHelper.underscoredUpper(`MEMBER_DETAIL_COMPONENT_KYC_${item.field_name}`);

          if (!item.isIgnoredEditing) {
            this.memberKycEdit.push(item);
          }
        });
        memberKycProperties = _.orderBy(memberKycProperties, ['order_index'], ['asc']);

        kycSection.MemberKycProperties = memberKycProperties;

        for (var i in this.memberKycEdit) {
          memberKycProperties.map((el) => {
            if (el.field_key === this.memberKycEdit[i].field_key) {
              this.memberKycEdit[i].value = el.value;
            }
          });
        }
        memberKycProperties.map((item) => {
          if (item.field_key) {
            this.memberKycDetail[item.field_key] = item;
          }
        });
      });
    },
    async onSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      try {
        const data = {};
        this.memberKycEdit = this.memberKycEdit.concat(this.kycPropertiesTmp);
        this.memberKycEdit
          .filter((el) => !el.isUploadFile)
          .forEach((item) => {
            data[item.field_key] = item.value;
          });
        const res = await this.$http.put(endpoints.updateMemberKycProperties(this.memberId), data);

        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('MEMBER_DETAIL_COMPONENT_TITLE_NOTIFY_SUCCESS'),
          text: this.$t('MEMBER_DETAIL_COMPONENT_TEXT_NOTIFY_SUCCESS'),
        });
        this.isFormChange = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MEMBER_DETAIL_COMPONENT_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    formatDateOfBirth(date) {
      return this.$moment(date).format('YYYY-MM-DD');
    },
    clearFromDate() {
      for (var i in this.memberKycEdit) {
        if (this.memberKycEdit[i].field_key == 'date_of_birth') {
          this.memberKycEdit[i].value = null;
        }
      }
    },
    checkDocumentKey(key) {
      return key.toLowerCase().startsWith('document');
    },
  },
};
</script>

<style lang="scss">
@mixin labels {
  margin: 0;
  width: 155px;
  font-weight: 300;
  line-height: 35px;
  color: #3e4b65;
  text-align: right;
  padding-right: 15px;
}
@mixin contents {
  margin: 0;
  width: calc(100% - 120px);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #3a4a7f;
  padding-top: 8px;
}
.form-memkyc-detail {
  .imgClass {
    margin-right: 10px;
    text-align: center;
    margin-bottom: 10px;
  }
  .individual {
    font-size: 18px;
    line-height: 24px;
  }
  .company {
    color: red;
  }
  .wrap-kycs {
    padding: 15px 0;
    .form-group {
      // .row {
      //   margin: 0 1.25rem 5px;
      // }
      .country-drop{
        .form-control{
          font-weight: bold;
          color: #3a4a7f;
        }
      }
      .input-group-text {
        display: inline-block;
        border-radius: initial;
      }
      .date-time-input {
        .date-time-inline {
          display: flex;
          .input-group-append {
            position: unset;
          }
          .vdatetime {
            padding-right: 0;
          }
        }
        .form-control {
          cursor: pointer;
        }
      }
      input {
        padding: 5px !important;
      }
    }
    .form-kycs {
      width: 100%;
      padding: 0 30px;
      @media only screen and (max-width: 900px) {
        padding: 0 20px;
      }
      div {
        display: flex;
        flex: 0 0 100%;
        width: 100%;
        label {
          @include labels;
        }
        p {
          @include contents;
        }
      }
    }
  }
  .imgList {
    border-left: 1px solid rgba(138, 147, 162, 0.5);
    min-height: 420px;
  }
  .edit-area {
    min-height: 40px;
  }
  .error-msg {
    color: #ff0000;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
  }
}
.edit-member-btn {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #2b7ef1 !important;
  outline: none;
  background: transparent;
  border: none;
  svg {
    width: 22px;
    height: 22px;
    font-size: 22px;
  }
}
</style>
