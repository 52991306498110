<template>
  <CRow class="member-details-container">
    <CCol col="12" md="9">
      <CCard>
        <CCardBody>
          <MemberDetailComponent
            :updateReferrerCode="updateReferrerCode"
            :updateMembershipTypeId="updateMembershipTypeId"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import MemberDetailComponent from '@/components/Member/MemberDetailComponent';
// import KYCMember from '@/components/Member/KYCMember';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'EditMembers',
  components: {
    MemberDetailComponent,
  },
  data() {
    return {
      memberId: '',
    };
  },
  mounted() {
    this.memberId = this.$route.params.memberId;
  },
  methods: {
    async updateReferrerCode(data) {
      const email = data.email;
      const params = {
        referrerCode: data.referrer_code,
      };
      try {
        const res = await this.$http.put(endpoints.updateReferrerCode(this.memberId), params);
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('MEMBER_DETAILS_PAGE_NOTIFY_SUCCESS_TITLE'),
          text: this.$t('MEMBER_DETAILS_PAGE_NOTIFY_REFRRER_SUCCESS_MESSAGE', { email: email }),
        });
        return res.data;
      } catch (err) {
        return responseHelper.getErrorMessage(err);
      }
    },
    async updateMembershipTypeId(data) {
      const email = data.email;
      const params = {
        membershipTypeId: data.membership_type_id,
      };
      try {
        const res = await this.$http.put(endpoints.updateMembershipTypeId(this.memberId), params);
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('MEMBER_DETAILS_PAGE_NOTIFY_SUCCESS_TITLE'),
          text: this.$t('MEMBER_DETAILS_PAGE_NOTIFY_MEMBERSHIP_SUCCESS_MESSAGE', { email: email }),
        });
        return res.data;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MEMBER_DETAILS_PAGE_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
        return responseHelper.getErrorMessage(err);
      }
    },
  },
};
</script>
<style lang="scss">
.member-details-container {
  .card-body {
    padding: 1.25rem 0;
  }
}
</style>
