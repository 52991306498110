import heic2any from 'heic2any';
import http from './http';

const cache = {};

export const imageHelper = {
  async convertHeicToUrl(link) {
    try {
      if (cache[link]) {
        return cache[link];
      }

      const res = await http.get(link, {
        responseType: 'blob',
        timeout: 30000,
      });
      const conversionResult = await heic2any({ blob: res, toType: 'png' });
      const result = URL.createObjectURL(conversionResult);
      cache[link] = result;

      return result;
    } catch (err) {
      console.log(err);
      return null;
    };
  },
  async convertHeicFromFile(file) {
    try {
      let conversionResult = await heic2any({ blob: file, toType: 'png' });
      return conversionResult;
    } catch (err) {
      console.log(err);
      return null;
    };
  },

};

export default imageHelper;




