<template>
  <CBadge :class="className2" color="secondary" @click="onClick">
    <span v-text="translateText"></span>
    <span v-if="description" class="ml-1">{{ description }}</span>
  </CBadge>
</template>

<script>
import _ from 'lodash';
import currencyHelper from '@/utils/currency-helper';
import stringHelper from '@/utils/string-helper';

export default {
  name: 'StakingBadge',
  data() {
    return {};
  },
  props: {
    type: {
      type: String,
      default: null,
    },
    className: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    click: {
      type: Function,
      default: null,
    },
  },
  computed: {
    className2() {
      return `staking-badge ${this.className} ${this.type} ${_.kebabCase(this.text)}`;
    },
    translateText() {
      if (!this.text) {
        return null;
      }

      const key = `${stringHelper.underscoredUpper(this.type)}_${stringHelper.underscoredUpper(this.text)}`;

      return this.$t(key);
    },
  },
  methods: {
    onClick() {
      if (this.click) {
        this.click();
      }
    },
  },
};
</script>

<style lang="scss">
.staking-badge {
  color: #4d5666;
  border-radius: 12px;
  background-color: #d8d8d8;
  font-weight: normal;
  padding: 4px 15px;
  font-size: 14px;

  &.membership-order-status {
    color: #4d5666;
    background-color: #d8d8d8;

    &.verify-payment {
      color: #ffffff;
      border-radius: 15px;
      background-color: #5da30b;
      font-size: 12px;
      line-height: 20px;
      padding: 3px 15px;
    }

    &.approved {
      color: #5da30b;
      background-color: transparent;
      padding: 3px 0px;

      &.no-border {
        border: none;
      }
    }
    &.rejected {
      color: #ff0000;
      background-color: transparent;
      padding: 3px 0px;

      &.no-border {
        border: none;
      }
    }
  }
  &.members-list-status {
    color: #fff;
    padding: 5px 15px;
    &.approved {
      background: #62d935;
    }
    &.insufficient {
      background: #e5aa47;
    }
    &.declined {
      background: #d0021b;
    }
    &.verify {
      background: #4c91de;
    }
    &.expired {
      background: #9b9b9b;
    }
    &.kyc-level {
      padding: 5px;
      width: 24px;
      height: 24px;
      border-radius: 24px;
    }
  }
  &.claim-request-status {
    color: #5da30b;
    font-size: 14px;
    letter-spacing: 0;
    background-color: transparent;

    &.payout-transferred {
      color: #5da30b;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;
      span {
        line-height: 22px;
      }
    }
    &.payout-rejected {
      color: #ff0000;
    }
    &.payout-pending {
      color: #ffffff;
      border-radius: 15px;
      padding: 5px 15px;
      background-color: #f7ae18;
      letter-spacing: 0;
      &.no-border {
        border: none;
        color: #f7ae18;
        border-radius: 15px;
        padding: 5px 0px;
        background-color: transparent;
      }
    }
  }
  &.reward-requests-status {
    color: #fff;
    padding: 5px 15px;
    &.pending {
      background: #e5aa47;
    }
    &.processing {
      background: #4c91de;
    }
    &.completed {
      background: #62d935;
    }
    &.failed {
      background: #d0021b;
    }
  }
  &.membership-type {
    border: none;
    padding: 5px 0px;
    background-color: transparent;
    &.platinum {
      color: #c0c0c0;
    }
    &.gold {
      font-weight: bold;
      color: #d4af37;
    }
    &.silver {
      color: #c0c0c0;
    }
  }
  &.kyc-status {
    font-size: 14px;
    background-color: transparent;
    text-align: left;
    padding-top: 10px;
    width: calc(100% - 120px);
  }
  &.exchange-currency-status {
    border: none;
    padding: 5px 0px;
    background-color: transparent;
  }
  &.exchange-transaction-status {
    border: none;
    padding: 5px 0px;
    background-color: transparent;
  }
  &.exchange-transaction-detail-status {
    border: none;
    background-color: transparent;
    color: #3a4a7f;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    padding: 5px 0px;
  }
  &.exchange-transaction-status {
    &.finished {
      color: #5da30b;
    }
    &.expired {
      color: #d4af37;
    }
  }
  &.exchange-currency-status {
    &.disabled {
      color: pink;
    }
    &.enabled {
      color: #5da30b;
    }
    &.comming-soon {
      color: #d4af37;
    }
  }

  &.currency-status {
    border: none;
    padding: 5px 0px;
    background-color: transparent;
  }
  &.currency-type {
    border: none;
    padding: 5px 0px;
    background-color: transparent;
  }
  &.notification-type {
    border: none;
    padding: 5px 0px;
    background-color: transparent;
  }
  &.notification-event {
    border: none;
    padding: 5px 0px;
    background-color: transparent;
  }
  &.tracking-email-status {
    background-color: transparent;
    padding: 5px 0px;
    &.success {
      color: #5da30b;
    }
    &.failed {
      color: #ff0000;
    }
  }
  &.ms-point-history-action {
    background-color: transparent;
    padding: 5px 0px;
  }
  &.ms-point-history-status {
    background-color: transparent;
    padding: 5px 0px;
    &.approved {
      color: #5da30b;
    }
    &.pending {
      color: #d4af37;
    }
    &.canceled {
      color: #ff0000;
    }
  }
  &.fiat-transaction-status {
    background-color: transparent;
    padding: 5px 0px;
    &.new {
      color: #d4af37;
    }
    &.running-checks {
      color: #3333ff;
    }
    &.processing {
      color: #3333ff;
    }
    &.complete {
      color: #5da30b;
    }
  }
  &.fiat-transaction-payment-method {
    background-color: transparent;
    padding: 5px 0px;
    &.debit-card {
      color: #3333ff;
    }
    &.apple-pay {
      color: #f7ae18;
    }
  }
  &.nexo-member-status {
    background-color: transparent;
    padding: 5px 0px;
    &.activated {
      color: #5da30b;
    }
  }
  &.survey-status {
    color: #4d5666;
    background-color: #d8d8d8;
    min-width: 90px;
    &.in-progress {
      color: #ffffff;
      border-radius: 15px;
      background-color: #ffa500;
      font-size: 12px;
      line-height: 20px;
      padding: 3px 15px;
    }

    &.ready {
      color: #5da30b;
      background: rgba(102, 187, 106, 0.1);
      padding: 3px 0px;
      border-radius: 15px;
      border: 1px solid;
    }
    &.draft {
      border-radius: 15px;
      background-color: transparent;
      font-size: 12px;
      line-height: 20px;
      padding: 3px 15px;
      border: 1px solid;
    }
    &.done {
      color: #ffffff;
      background: #66bb6a;
      padding: 3px 0px;
      border-radius: 15px;
    }
  }
  &.survey-type {
    color: #4d5666;
    background-color: #d8d8d8;
    min-width: 60px;
    padding: 3px 10px;

    &.quiz {
      color: #ffffff;
      border-radius: 4px;
      background-color: #3b4b7e;
      font-size: 12px;
      line-height: 20px;
    }
    &.survey {
      background-color: transparent;
      border-radius: 4px;
      border: 1px solid;
      font-size: 12px;
      line-height: 20px;
    }
  }
}
</style>
