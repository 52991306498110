export const STATE = [
  'PROVINCE_LIST_JAPAN_HOKKAIDO',
  'PROVINCE_LIST_JAPAN_AOMORI',
  'PROVINCE_LIST_JAPAN_IWATE',
  'PROVINCE_LIST_JAPAN_MIYAGI',
  'PROVINCE_LIST_JAPAN_AKITA',
  'PROVINCE_LIST_JAPAN_YAMAGATA',
  'PROVINCE_LIST_JAPAN_FUKUSHIMA',
  'PROVINCE_LIST_JAPAN_IBARAKI',
  'PROVINCE_LIST_JAPAN_TOCHIGI',
  'PROVINCE_LIST_JAPAN_GUNMA',
  'PROVINCE_LIST_JAPAN_SAITAMA',
  'PROVINCE_LIST_JAPAN_CHIBA',
  'PROVINCE_LIST_JAPAN_TOKYO',
  'PROVINCE_LIST_JAPAN_KANAGAWA',
  'PROVINCE_LIST_JAPAN_NIIGATA',
  'PROVINCE_LIST_JAPAN_TOYAMA',
  'PROVINCE_LIST_JAPAN_ISHIKAWA',
  'PROVINCE_LIST_JAPAN_FUKUI',
  'PROVINCE_LIST_JAPAN_YAMANASHI',
  'PROVINCE_LIST_JAPAN_NAGANO',
  'PROVINCE_LIST_JAPAN_GIFU',
  'PROVINCE_LIST_JAPAN_SHIZUOKA',
  'PROVINCE_LIST_JAPAN_AICHI',
  'PROVINCE_LIST_JAPAN_MIE',
  'PROVINCE_LIST_JAPAN_SHIGA',
  'PROVINCE_LIST_JAPAN_KYOTO',
  'PROVINCE_LIST_JAPAN_OSAKA',
  'PROVINCE_LIST_JAPAN_HYOGO',
  'PROVINCE_LIST_JAPAN_NARA',
  'PROVINCE_LIST_JAPAN_WAKAYAMA',
  'PROVINCE_LIST_JAPAN_TOTTORI',
  'PROVINCE_LIST_JAPAN_SHIMANE',
  'PROVINCE_LIST_JAPAN_OKAYAMA',
  'PROVINCE_LIST_JAPAN_HIROSHIMA',
  'PROVINCE_LIST_JAPAN_YAMAGUCHI',
  'PROVINCE_LIST_JAPAN_TOKUSHIMA',
  'PROVINCE_LIST_JAPAN_KAGAWA',
  'PROVINCE_LIST_JAPAN_EHIME',
  'PROVINCE_LIST_JAPAN_KOCHI',
  'PROVINCE_LIST_JAPAN_FUKUOKA',
  'PROVINCE_LIST_JAPAN_SAGA',
  'PROVINCE_LIST_JAPAN_NAGASAKI',
  'PROVINCE_LIST_JAPAN_KUMAMOTO',
  'PROVINCE_LIST_JAPAN_OITA',
  'PROVINCE_LIST_JAPAN_MIYAZAKI',
  'PROVINCE_LIST_JAPAN_KAGOSHIMA',
  'PROVINCE_LIST_JAPAN_OKINAWA', 
  ]