<template>
  <div>
    <div class="form-mem-detail">
      <CRow>
        <CCol class="back-to-members" md="12" sm="12">
          <div class="form-group">
            <router-link to="/admin/members">
              <CIcon name="cil-arrow-left" class="mr-1" />
              {{ $t('MEMBER_DETAIL_COMPONENT_BACK_TO_MEMBERS') }}
            </router-link>
          </div>
        </CCol>

        <CCol md="3" sm="12">
          <label>{{ $t('MEMBER_DETAIL_COMPONENT_LABEL_EMAIL') }}</label>
          <p class="text-bold">{{ member.email }}</p>
        </CCol>
        <CCol md="3" sm="12">
          <label>{{ $t('MEMBER_DETAIL_COMPONENT_LABEL_JOINED') }}</label>
          <p class="text-bold" v-if="member.created_at">{{ member.created_at | moment('YYYY-MM-DD HH:mm') }}</p>
        </CCol>
        <CCol md="3" sm="12">
          <label>{{ $t('MEMBER_DETAIL_COMPONENT_LABEL_LEVEL') }}</label>
          <p class="text-bold">{{ member.kyc_level || '-' }}</p>
        </CCol>
        <CCol class="field-referrer field-membership" md="3" sm="12">
          <ValidationProvider
            v-if="isAllowedUpdateMember"
            name="MEMBER_DETAIL_COMPONENT_FIELD_NAME_VALIDATE_MEMBERSHIP"
            v-slot="{ errors }"
            rules
            ref="membership"
          >
            <CSelect
              :label="$t('MEMBER_DETAIL_COMPONENT_LABEL_MEMBERSHIP') + ':'"
              :value.sync="updateMember.membership_type_id"
              :options="membershipTypeOptions"
              @change="isMembershipTypeFocus = true"
            />
            <CRow v-if="errors[0]">
              <CCol>
                <p class="error-msg mt-1">{{ errors[0] }}</p>
              </CCol>
            </CRow>
          </ValidationProvider>
          <div v-if="isMembershipTypeFocus && isAllowedUpdateMember" class="referrer-actions">
            <CButton class="referrer-submit" @click="submitMembershipType(updateMember)">
              <CIcon name="cil-check-alt" />
            </CButton>
            <CButton @click="cancelMembershipType">
              <CIcon name="cil-x" />
            </CButton>
          </div>
        </CCol>

        <CCol class="field-referrer" md="3" sm="12">
          <label v-if="!isAllowedUpdateMember">{{ $t('MEMBER_DETAIL_COMPONENT_LABEL_REFERRER') }}</label>
          <p v-if="!isAllowedUpdateMember">{{ member.referrer_code || updateMember.referrer_code }}</p>
          <ValidationProvider
            v-if="isAllowedUpdateMember"
            name="MEMBER_DETAIL_COMPONENT_FIELD_NAME_VALIDATE_REFERRER"
            v-slot="{ errors }"
            rules="alpha_num"
            ref="referrerCode"
          >
            <CInput
              :label="$t('MEMBER_DETAIL_COMPONENT_LABEL_REFERRER')"
              type="text"
              v-model="updateMember.referrer_code"
              class
              @focus="handleFocusReferrer"
              @blur="updateMember.referrer_code != '' ? (isReferrerFocus = true) : (isReferrerFocus = false)"
            />
            <CRow v-if="errMsg || errors[0]">
              <CCol>
                <p class="error-msg mt-1">{{ errors[0] || errMsg }}</p>
              </CCol>
            </CRow>
          </ValidationProvider>
          <div v-if="isReferrerFocus && isAllowedUpdateMember" class="referrer-actions">
            <CButton class="referrer-submit" @click="submitReferrerCode(updateMember)">
              <CIcon name="cil-check-alt" />
            </CButton>
            <CButton @click="cancelReferrerCode">
              <CIcon name="cil-x" />
            </CButton>
          </div>
        </CCol>
        <CCol md="3" sm="12">
          <label>{{ $t('MEMBER_DETAIL_COMPONENT_LABEL_REFERRAL_CODE') }}</label>
          <p>
            {{ member.referral_code || '-' }}
          </p>
        </CCol>

        <CCol md="3" sm="12">
          <div v-if="isAllowedUpdate2FA" class="field-status">
            <label>{{ $t('MEMBER_DETAIL_COMPONENT_LABEL_STATUS_2FA') }}</label>
            <div class="edit-2fa-status">
              <label class="btn-switch">
                <input disabled type="checkbox" class="checkbox" v-model="isActiveStatus2FA" />
                <span class="check-silder"></span>
              </label>
            </div>
          </div>
        </CCol>
        <CCol class="field-status" md="3" sm="12">
          <label>{{ $t('MEMBER_DETAIL_COMPONENT_LABEL_STATUS') }}</label>
          <p @click="member.status == 'Verify payment' ? verifyPayment(member.latest_membership_order_id) : null">
            <StakingBadge class="text-bold" type="member-status" :text="member.status" :class="className" />
            <CButton v-if="member.status === 'Unactivated'" class="btn-send-avtive-email ml-4" @click="sendEmailActiveMember">{{
              $t('MEMBER_DETAIL_COMPONENT_MODAL_BUTTON_SEND_EMAIL_ACTIVE')
            }}</CButton>
          </p>
        </CCol>
        <CCol md="12" sm="12" class="field-ban mb-4" >
          <label class="mr-2">{{ $t('MEMBER_DETAIL_COMPONENT_LABEL_BAN_DATE_PICKER') }}</label>
          <ValidationProvider
            :name="$t('MEMBER_DETAIL_COMPONENT_FIELD_NAME_VALIDATE_DATE_BAN')"
            v-slot="{ errors }"
            rules="required|futureDate"
            ref="lockoutEnd"
          >
            <date-picker
              v-model="lockoutEnd"
              valueType="date"
              type="datetime"
              :show-second="false"
              format="YYYY-MM-DD HH:mm"
            ></date-picker>
            <CButton class="btn-ban ml-4" v-if="member.status !== 'BANNED' && isAllowedUpdateMember" @click="banMember">
              {{ $t('MEMBER_DETAIL_COMPONENT_MODAL_BUTTON_BAN') }}
            </CButton>
            <CButton v-if="member.status === 'BANNED' && isAllowedUpdateMember === true" class="btn-unban ml-4" @click="unbanMember">
              {{ $t('MEMBER_DETAIL_COMPONENT_MODAL_BUTTON_UNBAN') }}
            </CButton>
            <CRow v-if="errMsg || errors[0]">
              <CCol>
                <p class="error-msg mt-1">{{ errors[0] || errMsg }}</p>
              </CCol>
            </CRow>
          </ValidationProvider>
        </CCol>
        <CCol md="3" sm="12">
          <label v-if="isAllowedUpdateMember">{{ $t('MEMBER_DETAIL_COMPONENT_TEST_ACCOUNT') }}</label>
          <div class="btn-switch-wrapper" v-if="isAllowedUpdateMember">
            <label class="btn-switch">
              <input type="checkbox" class="checkbox" :checked="member.is_testing_account" @change="toggleTestAccountFlag" />
              <span class="check-silder"></span>
            </label>
          </div>
        </CCol>
        <CCol md="3" sm="12">
          <label v-if="!isAllowedUpdateMember">{{ $t('MEMBER_DETAIL_COMPONENT_FIELD_NAME_VALIDATE_MEMBERSHIP') }}</label>
          <p v-if="!isAllowedUpdateMember">
            <StakingBadge class="no-border" type="membership-type" :text="member.membership_type" />
          </p>
        </CCol>
      </CRow>
        <CRow class="form-actions mt-3" v-if="isAllowedUpdate2FA && isActiveStatus2FA">
           <CCol lg="12" md="12" class="update-kyc">
            <CButton
            @click="show2FAConfirmationModal()"
            color="primary"
            class="btn btn-update"
            >{{ $t('MEMBER_DETAIL_COMPONENT_BUTTON_REMOVE_STATUS_2FA') }}
            </CButton>
        </CCol>
      </CRow>
      <!-- <ul>
      <li class="field-status"></li>
      <li>
        <label>{{ $t('MEMBER_DETAIL_COMPONENT_LABEL_KYC_STATUS') }}</label>
        <StakingBadge type="kyc-status" :text="member.kyc_status" />
      </li>
    </ul> -->
      <CModal
        class="staking-confirmation-modal confirm-approving-memberKyc-modal"
        :show.sync="isShownApproveConfirmationModal"
        :centered="true"
      >
        <template #header>
          <CButtonClose class="close-modal" @click="closeApproveConfirmationModal" />
        </template>
        <div>
          <div class="pb-4 tit-dele">
            <h4>{{ $t('MEMBER_DETAIL_COMPONENT_MODAL_TITLE_CONFIRM_KYC_APPROVE') }}</h4>
          </div>
          <div class="text-modal">{{ $t('MEMBER_DETAIL_COMPONENT_MODAL_CONTENT_APPROVE') }}</div>
        </div>
        <template #footer>
          <CButton class="btn-cancel" @click="closeApproveConfirmationModal" color="dark" variant="outline">{{
            $t('MEMBER_DETAIL_COMPONENT_MODAL_BUTTON_CANCEL')
          }}</CButton>

          <CButton @click="updateStatusMemberKyc('APPROVED', member)" class="btn-ok">{{
            $t('MEMBER_DETAIL_COMPONENT_MODAL_BUTTON_CONFIRM_APPROVE')
          }}</CButton>
        </template>
      </CModal>
      <CModal
        class="staking-confirmation-modal confirm-approving-memberKyc-modal"
        :show.sync="isShown2FAConfirmationModal"
        :centered="true"
      >
        <template #header>
          <CButtonClose class="close-modal" @click="close2FAConfirmationModal" />
        </template>
        <div>
          <div class="pb-4 tit-dele">
            <h4>{{ $t('MEMBER_DETAIL_COMPONENT_MODAL_TITLE_CONFIRM_REMOVE_STATUS_2FA') }}</h4>
          </div>
          <div class="text-modal">{{ $t('MEMBER_DETAIL_COMPONENT_MODAL_CONTENT_REMOVE_STATUS_2FA') }}</div>
        </div>
        <template #footer>
          <CButton class="btn-cancel" @click="close2FAConfirmationModal" color="dark" variant="outline">{{
            $t('MEMBER_DETAIL_COMPONENT_MODAL_BUTTON_CANCEL')
          }}</CButton>

          <CButton @click="updateStatus2FA()" class="btn-ok">{{
            $t('MEMBER_DETAIL_COMPONENT_MODAL_BUTTON_CONFIRM_REMOVE_STATUS_2FA')
          }}</CButton>
        </template>
      </CModal>
    </div>
    <div class="form-mem-detail seconds" v-if="Number(member.kyc_level) !== 0">
      <MemberDetailKyc
        ref="excuteActionMemberChild"
        v-if="isAllowedViewMemberKycList"
        :enabledEditKyc="enabledEditKyc"
        :isEnabledEditKycContent="isEnabledEditKycContent"
        :isEnabledEditKYC="isEnabledEditKYC"
        :memberId="memberId"
        :kycLevel="member.kyc_level"
        @toggleShowEdit="toggleShowEdit"
      />
      <CRow class="form-actions form-actions-update mt-3" v-if="enabledEditKyc && isEnabledEditKycContent">
        <CCol lg="12" md="12" class="update-kyc">
          <CButton @click="resetForm()" class="btn btn-secondary btn-cancel">{{ $t('MEMBER_DETAIL_COMPONENT_BUTTON_CANCEL') }}</CButton>
          <CButton @click="updateMemberKyc" color="primary" class="btn btn-update">{{
            $t('MEMBER_DETAIL_COMPONENT_BUTTON_UPDATE')
          }}</CButton>
        </CCol>
      </CRow>

      <CRow class="form-actions mt-3" v-if="isEnabledUpdateKycStatus">
        <CCol lg="12" md="12" class="update-kyc-status">
          <CButton @click="shownInsufficientModal" class="btn btn-insufficient">{{
            $t('MEMBER_DETAIL_COMPONENT_BUTTON_INSUFFICIENT')
          }}</CButton>
          <CButton @click="shownDeclinedModal" class="btn btn-danger btn-decline">{{
            $t('MEMBER_DETAIL_COMPONENT_BUTTON_DECLIENED')
          }}</CButton>
          <CButton @click="showApproveConfirmationModal()" color="primary" class="btn btn-approve">{{
            $t('MEMBER_DETAIL_COMPONENT_BUTTON_APPROVED')
          }}</CButton>
        </CCol>
      </CRow>

      <ModalInsufficient
        :member="member"
        :isShownInsufficientModal="isShownInsufficientModal"
        @setMemberKycStatus="setMemberKycStatus"
        @closeInsufficientModal="closeInsufficientModal"
      />
      <ModalDeclined
        :member="member"
        :isShownDeclinedModal="isShownDeclinedModal"
        @setMemberKycStatus="setMemberKycStatus"
        @closeDeclinedModal="closeDeclinedModal"
      />
    </div>
  </div>
</template>

<script>
import ModalDeclined from '@/components/Member/ModalDeclinedComponent';
import ModalInsufficient from '@/components/Member/ModalInsufficientComponent';
import { mapState, mapActions, mapGetters } from 'vuex';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import { Constants } from '@/constants';
import StakingBadge from '@/components/StakingBadge';
import MemberDetailKyc from '@/components/Member/MemberDetailKycComponent';
import { KYCStatus } from '@/enums';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'MemberDetail',
  data() {
    return {
      updateMember: {
        membership_type_id: '',
        referrer_code: '',
        email: '',
      },
      member: {},
      memberId: '',
      membershipTypeOptions: [],
      isReferrerFocus: false,
      isMembershipTypeFocus: false,
      isAllowedViewMemberKycList: false,
      isAllowedUpdateMember: false,
      isShownApproveConfirmationModal: false,
      isEnabledEditKYC: false,
      isFormChange: false,
      isAllowedUpdateMemberKycStatus: false,
      className: '',
      errMsg: '',
      isShownDeclinedModal: false,
      isShownInsufficientModal: false,
      isActiveStatus2FA: false,
      isShown2FAConfirmationModal: false,
      isAllowedUpdate2FA: false,
      isEnabledEditKycContent: false,
      lockoutEnd: null,
    };
  },
  props: {
    updateReferrerCode: {
      type: Function,
      default: null,
    },
    updateMembershipTypeId: {
      type: Function,
      default: null,
    },
  },
  components: {
    StakingBadge,
    MemberDetailKyc,
    ModalDeclined,
    ModalInsufficient,
    DatePicker,
  },
  computed: {
    ...mapGetters(['userPermissions']),
    isEnabledUpdateKycStatus() {
      return (
        this.isAllowedUpdateMemberKycStatus &&
        (this.member.kyc_status === KYCStatus.InReview || this.member.kyc_status === KYCStatus.Insufficient)
      );
    },
    enabledEditKyc() {
      return this.isEnabledEditKYC && (this.member.kyc_status === KYCStatus.InReview || this.member.kyc_status === KYCStatus.Insufficient);
    },
  },
  async mounted() {
    this.checkPermissions();
    this.memberId = this.$route.params.memberId;
    await this.getMemberDetail();
    this.className = this.member.status.replace(' ', '-').toLowerCase();
    this.lockoutEnd = new Date(this.member.lockout_end);
    this.membershipTypeOptions = await this.getMembershipType();
    const indexOfAll = this.membershipTypeOptions.findIndex((e) => e.value == 'all');
    this.membershipTypeOptions.splice(indexOfAll, 1);
    this.checkMembershipType();
  },
  methods: {
    ...mapActions(['getMembershipType']),
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach((item) => {
          if (item.name === 'MEMBERSHIP_VIEW_MEMBER_KYC_LIST') {
            this.isAllowedViewMemberKycList = true;
          }
          if (item.name === 'MEMBERSHIP_UPDATE_MEMBER') {
            this.isAllowedUpdateMember = true;
          }
          if (item.name === 'MEMBERSHIP_UPDATE_MEMBER_KYC_PROPERTIES') {
            this.isEnabledEditKYC = true;
          }
          if (item.name === 'MEMBERSHIP_UPDATE_MEMBER_KYC_STATUS') {
            this.isAllowedUpdateMemberKycStatus = true;
          }
          if (item.name === 'REMOVE_2FA') {
            this.isAllowedUpdate2FA = true;
          }
        });
      }
    },
    async getMemberDetail() {
      try {
        const res = await this.$http.get(endpoints.getMemberDetails(this.memberId));
        this.member = res.data;
        this.updateMember.referrer_code = this.member.referrer_code || '';
        this.updateMember.email = this.member.email || '';
        this.updateMember.membership_type_id = this.member.membership_type_id;
        this.isActiveStatus2FA = this.member.twofa_enable_flg || this.member.twofa_download_key_flg;
        // document.body.getElementsByClassName('breadcrumb-item active')[0].innerHTML = this.$t('MEMBER_DETAIL_COMPONENT_BREADCRUMB_EMAIL', {
        //   email: this.member.email,
        // });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MEMBER_DETAIL_COMPONENT_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async unbanMember() {
      try {
        await this.$http.put(endpoints.unbanMember(this.memberId));
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('MEMBER_DETAIL_COMPONENT_TITLE_NOTIFY_SUCCESS'),
          text: this.$t('MEMBER_DETAIL_COMPONENT_TEXT_NOTIFY_SUCCESS'),
          duration: 1000,
        });
        await this.getMemberDetail();
        this.lockoutEnd = null;
        this.className = this.member.status.replace(' ', '-').toLowerCase();
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MEMBER_DETAIL_COMPONENT_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async banMember() {
      try {
        const isValid = await this.$refs.lockoutEnd.validate();
        if (!isValid.valid) return;
        await this.$http.put(endpoints.banMember(this.memberId), { lockoutEnd: this.lockoutEnd });
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('MEMBER_DETAIL_COMPONENT_TITLE_NOTIFY_SUCCESS'),
          text: this.$t('MEMBER_DETAIL_COMPONENT_TEXT_NOTIFY_SUCCESS'),
          duration: 1000,
        });
        await this.getMemberDetail();
        this.lockoutEnd = new Date(this.member.lockout_end);
        this.className = this.member.status.replace(' ', '-').toLowerCase();
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MEMBER_DETAIL_COMPONENT_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    cancelReferrerCode() {
      this.updateMember.referrer_code = this.member.referrer_code || '';
      this.isReferrerFocus = false;
    },
    cancelMembershipType() {
      this.isMembershipTypeFocus = false;
    },
    async submitReferrerCode(data) {
      const isValid = await this.$refs.referrerCode.validate();
      if (!isValid.valid) {
        return;
      } else {
        const result = await this.updateReferrerCode(data);
        if (result == true) {
          this.errMsg = '';
        } else {
          this.errMsg = result;
        }
        this.isReferrerFocus = false;
      }
    },
    async submitMembershipType(data) {
      const isValid = await this.$refs.membership.validate();
      if (!isValid) {
        return;
      }

      const isSuccess = await this.updateMembershipTypeId(data);
      if (isSuccess) {
        this.cancelMembershipType();
        await this.getMemberDetail();
        this.checkMembershipType();
      }
    },
    formatDateOfBirth(date) {
      return this.$moment(date).format('YYYY-MM-DD');
    },
    verifyPayment(id) {
      this.$router.push(`/admin/membership-orders/${id}/details`);
    },
    checkMembershipType() {
      if (this.member.membership_type != 'Basic') {
        const indexOfBasic = this.membershipTypeOptions.findIndex((e) => e.value == 'Basic');
        if (indexOfBasic >= 0) {
          this.membershipTypeOptions.splice(indexOfBasic, 1);
        }
      }
    },
    handleFocusReferrer() {
      this.isReferrerFocus = true;
      this.errMsg = '';
    },
    showApproveConfirmationModal() {
      this.isShownApproveConfirmationModal = true;
    },
    closeApproveConfirmationModal() {
      this.isShownApproveConfirmationModal = false;
    },
    show2FAConfirmationModal() {
      this.isShown2FAConfirmationModal = true;
    },
    close2FAConfirmationModal() {
      this.isShown2FAConfirmationModal = false;
    },
    async updateStatusMemberKyc(status, member) {
      try {
        var data = {
          status: status,
        };
        var res = await this.$http.put(endpoints.updateMemberKyc(member.id, member.kyc_id), data);
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('MEMBER_DETAIL_COMPONENT_TITLE_NOTIFY_SUCCESS'),
          text: this.$t('MEMBER_DETAIL_COMPONENT_TEXT_NOTIFY_SUCCESS'),
          duration: 1000,
        });
        this.setMemberKycStatus(res.data);
        this.closeApproveConfirmationModal();
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MEMBER_DETAIL_COMPONENT_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
          duration: 1000,
        });
      }
    },
    updateMemberKyc() {
      this.$refs.excuteActionMemberChild.onSubmit();
    },
    async updateStatus2FA() {
      try {
        var res = await this.$http.put(endpoints.updateStatus2FA(this.memberId));
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('MEMBER_DETAIL_COMPONENT_TITLE_NOTIFY_SUCCESS'),
          text: this.$t('MEMBER_DETAIL_COMPONENT_TEXT_NOTIFY_SUCCESS'),
          duration: 1000,
        });
        this.close2FAConfirmationModal();
        this.isActiveStatus2FA = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MEMBER_DETAIL_COMPONENT_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
          duration: 1000,
        });
      }
    },
    openWarningLeaveModal() {
      this.$refs.excuteActionMemberChild.openWarningLeaveModal();
    },
    shownDeclinedModal() {
      this.isShownDeclinedModal = true;
    },
    closeDeclinedModal() {
      this.isShownDeclinedModal = false;
    },
    shownInsufficientModal() {
      this.isShownInsufficientModal = true;
    },
    closeInsufficientModal() {
      this.isShownInsufficientModal = false;
    },
    async setMemberKycStatus(data) {
      this.member.kyc_status = data.status;
      const memberKyc = await this.$refs.excuteActionMemberChild.getMemberKycs();
      this.$refs.excuteActionMemberChild.getMemberKyc(memberKyc);
    },
    async sendEmailActiveMember() {
      try {
        await this.$http.post(endpoints.sendEmailActiveMember(this.memberId));

        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('MEMBER_DETAIL_COMPONENT_TITLE_NOTIFY_SUCCESS'),
          text: this.$t('MEMBER_DETAIL_COMPONENT_MESSAGE_NOTIFY_SUCCESS'),
        });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MEMBER_DETAIL_COMPONENT_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    resetForm() {
      this.$refs.excuteActionMemberChild.resetForm();
    },
    toggleShowEdit() {
      this.isEnabledEditKycContent = !this.isEnabledEditKycContent;
    },
    async toggleTestAccountFlag() {
      try {
        await this.$http.put(endpoints.setTestingAccount(this.member.id), {
          is_testing_account: !this.member.is_testing_account,
        });
        await this.getMemberDetail()
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('MEMBER_DETAIL_COMPONENT_TITLE_NOTIFY_SUCCESS'),
          text: this.$t('MEMBER_DETAIL_COMPONENT_TEXT_NOTIFY_SUCCESS'),
        });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MEMBER_DETAIL_COMPONENT_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>

<style lang="scss">
@mixin labels {
  margin: 0;
  min-width: 100px;
  font-weight: 300;
  line-height: 35px;
  color: #3e4b65;
  text-align: right;
  padding-right: 15px;
}
@mixin contents {
  margin: 0;
  width: calc(100% - 155px);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #3a4a7f;
  padding-top: 7px;
}
.seconds {
  border-top: 10px solid #ebedef;
}
.form-mem-detail {
  padding: 0 29px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }
  .text-bold {
    font-weight: bold;
    color: #3a4a7f;
    padding: 0px;
  }
  .member-status {
    background: transparent;
    &.verify-payment {
      color: #fff;
      background: #5da30b;
      padding: 4px 12px;
      border-radius: 19px;
      font-size: 12px;
      cursor: pointer;
    }
    &.deactivated {
      color: #ff0000;
    }
    &.gold {
      color: #d4af37;
    }
    &.silver {
      color: #9b9b9b;
    }
    &.fee-accepted {
      color: #3a4a7f;
    }
    &.active {
      color: #3a4a7f;
    }
  }
  ul {
    label {
      @include labels;
    }
    input {
      margin: 0;
      line-height: 22px;
      font-weight: 500;
      width: calc(100% - 100px);
      color: #3a4a7f;
    }
    .kyc-status {
      padding-left: 0;
    }
    .form-group {
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
      margin-bottom: 0;
      select,
      input {
        width: calc(100% - 120px);
      }
    }
    > span {
      width: 100%;
    }
  }
  .wrap-confirm-btn {
    flex: 0 0 100%;
    width: 100%;
    justify-content: flex-end;
    margin: 15px 0;
    padding-right: 10px;
    @media only screen and (max-width: 767px) {
      padding-right: 0;
    }
    .confirm-btn {
      display: block;
      background-color: #3b4b7e;
      border: none;
      border-radius: 32px;
      width: 200px;
      height: 32px;
      padding: 0 30px;
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      outline: none !important;
      margin: 0;
    }
  }
  .back-to-members {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 30px;
    a {
      font-size: 14px;
      font-weight: 300;
      line-height: 22px;
      color: #695fe5;
      text-decoration: none;
    }
  }
  .field-referrer {
    position: relative;
    .referrer-actions {
      position: absolute;
      right: 20px;
      top: 66px;
      z-index: 99;
      button {
        width: 35px;
        height: 35px;
        background: #f5f6f8;
        color: #3a4a7f;
        box-shadow: -2px 2px 5px -2px #00000080;
        padding: 0;
        &.referrer-submit {
          margin-right: 10px;
        }
      }
    }
    .error-msg {
      font-size: 14px;
      font-weight: 300;
      color: #ff0000;
      line-height: 20px;
      // width: calc(100% - 145px);
      // margin-left: 145px;
      margin-bottom: 10px;
      padding: 0;
    }
  }
  .field-status {
    flex-wrap: wrap;
    .btn-send-avtive-email {
      min-width: 145px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      color: #fff;
      background-color: #5da30b;
      border: none;
    }
  }

  .field-ban {
    flex-wrap: wrap;
    .btn-ban {
      min-width: 145px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      color: #fff;
      background-color: red;
      border: none;
    }
    .btn-unban {
      min-width: 145px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      color: #fff;
      background-color: #5da30b;
      border: none;
    }
  }

  .form-actions-update {
    border-bottom: 1px solid #e4e5e7;
  }
  .form-actions {
    // margin-right: 1.25rem;
    text-align: right;
    .btn {
      border: none;
      border-radius: 32px;
      width: 200px;
      height: 32px;
      padding: 0 30px;
      color: #fff;
      margin: 0 0 15px 15px;
      letter-spacing: 0.5px;
      line-height: 16px;
      text-align: center;
      font-size: 14px;
      border-radius: 4px;
      height: 36px;

      &.btn-approve {
        background-color: #5da30b;
      }

      &.btn-decline {
        color: #ffffff;
        font-weight: 500;
      }

      &.btn-insufficient {
        background-color: transparent;
        color: #6c65e4;
        font-size: 14px;
        letter-spacing: 0;
      }
      &.btn-primary {
        background: #3b4b7e;
      }

      &.btn-update {
        color: #4d5666;
        font-weight: 500;
        letter-spacing: 0.5px;
        text-align: center;
        background-color: #fff;
        border: 1px solid #3b4b7e;
        border-radius: 4px;
      }

      &.btn-cancel {
        background-color: transparent;
        color: #6c65e4;
        font-size: 14px;
        letter-spacing: 0;
      }
    }
  }
  .error-msg {
    color: #ff0000;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
  }
  .edit-2fa-status {
    .btn-switch {
      position: relative;
      display: inline-block;
      min-width: 40px;
      height: 24px;

      .checkbox {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .check-silder::before {
          border-color: #009900;
          transform: translateX(16px);
        }

        &:checked + .check-silder {
          background: #009900;
        }
      }

      .check-silder {
        position: absolute;
        cursor: pointer;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: #fff;
        border: 1px solid #dadada;
        transition: 0.4s;
        border-radius: 24px;

        &:before {
          position: absolute;
          content: '';
          height: 20px;
          width: 20px;
          left: 1px;
          bottom: 1px;
          top: 1px;
          background: #fff;
          border: 1px solid #dadada;
          transition: 0.4s;
          border-radius: 50%;
        }
      }
    }
  }
  .btn-2fa {
    color: #4d5666;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #3b4b7e;
    border-radius: 4px;
    margin-bottom: 10px;
  }
}

.confirm-approving-memberKyc-modal {
  h4 {
    height: 21px;
    color: #4a4a4a;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
  }
  .comments-label {
    color: #4a4a4a;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
  }
  .modal-content {
    .form-text.text-muted {
      color: rgba(138, 147, 162, 0.5);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
    }

    .reason-option {
      color: #3a4a7f;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
    }
    .reason-description {
      color: #3a4a7f;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
    }

    .modal-footer {
      .btn-ok {
        background-color: #5da30b;
      }
    }
  }
}
</style>
