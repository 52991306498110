var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-memkyc-detail"},[_c('ValidationObserver',{ref:"form",on:{"change":function($event){_vm.isFormChange = true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(_vm.accountType.value === 'Individual' || _vm.accountType.value === 'Company')?_c('CRow',{staticClass:"wrap-kycs"},[_c('CCol',{attrs:{"md":"8"}},[_c('CRow',[_c('CCol',{attrs:{"lg":"2","md":"2"}},[_c('p',{staticClass:"text-bold individual"},[_vm._v(_vm._s(_vm.$t('MEMBER_DETAIL_COMPONENT_LABEL_LEVEL') + ' ' + _vm.kycLevel))])]),_c('CCol',{attrs:{"lg":"10","md":"10"}},[(_vm.accountType.value === 'Individual')?_c('div',[_c('img',{staticClass:"imgClass",attrs:{"src":require("@/assets/images/Shape.svg")}}),_c('span',{staticClass:"text-bold individual"},[_vm._v(_vm._s(_vm.$t(("MEMBER_ACCOUNT_TYPE_" + (_vm.accountType.value)).toUpperCase())))])]):_c('div',[_c('img',{staticClass:"imgClass",attrs:{"src":require("@/assets/images/icon_company.svg")}}),_c('span',{staticClass:"text-bold company"},[_vm._v(_vm._s(_vm.$t(("MEMBER_ACCOUNT_TYPE_" + (_vm.accountType.value)).toUpperCase())))])])]),(_vm.memberKycDetail.last_name)?_c('CCol',{staticClass:"form-group",attrs:{"lg":"6","md":"6"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t(_vm.memberKycDetail.last_name.textKey)))]),_c('ValidationProvider',{attrs:{"name":_vm.$t(("MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_" + (_vm.memberKycDetail.last_name.field_key.toUpperCase()))),"rules":_vm.getRules(_vm.memberKycDetail.last_name)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.memberKycDetail.last_name.value),expression:"memberKycDetail.last_name.value"}],staticClass:"form-control text-bold",attrs:{"type":"text","disabled":_vm.getDisable()},domProps:{"value":(_vm.memberKycDetail.last_name.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.memberKycDetail.last_name, "value", $event.target.value)}}}),_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.memberKycDetail.first_name)?_c('CCol',{staticClass:"form-group",attrs:{"lg":"6","md":"6"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t(_vm.memberKycDetail.first_name.textKey)))]),_c('ValidationProvider',{attrs:{"name":_vm.$t(("MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_" + (_vm.memberKycDetail.first_name.field_key.toUpperCase()))),"rules":_vm.getRules(_vm.memberKycDetail.first_name)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.memberKycDetail.first_name.value),expression:"memberKycDetail.first_name.value"}],staticClass:"form-control text-bold",attrs:{"type":"text","disabled":_vm.getDisable()},domProps:{"value":(_vm.memberKycDetail.first_name.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.memberKycDetail.first_name, "value", $event.target.value)}}}),_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.memberKycDetail.last_name_kanji)?_c('CCol',{staticClass:"form-group",attrs:{"lg":"6","md":"6"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t(_vm.memberKycDetail.last_name_kanji.textKey)))]),_c('ValidationProvider',{attrs:{"name":_vm.$t(("MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_" + (_vm.memberKycDetail.last_name_kanji.field_key.toUpperCase()))),"rules":_vm.getRules(_vm.memberKycDetail.last_name_kanji)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.memberKycDetail.last_name_kanji.value),expression:"memberKycDetail.last_name_kanji.value"}],staticClass:"form-control text-bold",attrs:{"type":"text","disabled":_vm.getDisable()},domProps:{"value":(_vm.memberKycDetail.last_name_kanji.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.memberKycDetail.last_name_kanji, "value", $event.target.value)}}}),_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.memberKycDetail.first_name_kanji)?_c('CCol',{staticClass:"form-group",attrs:{"lg":"6","md":"6"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t(_vm.memberKycDetail.first_name_kanji.textKey)))]),_c('ValidationProvider',{attrs:{"name":_vm.$t(("MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_" + (_vm.memberKycDetail.first_name_kanji.field_key.toUpperCase()))),"rules":_vm.getRules(_vm.memberKycDetail.first_name_kanji)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.memberKycDetail.first_name_kanji.value),expression:"memberKycDetail.first_name_kanji.value"}],staticClass:"form-control text-bold",attrs:{"type":"text","disabled":_vm.getDisable()},domProps:{"value":(_vm.memberKycDetail.first_name_kanji.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.memberKycDetail.first_name_kanji, "value", $event.target.value)}}}),_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.memberKycDetail.date_of_birth)?_c('CCol',{staticClass:"form-group",attrs:{"lg":"6","md":"6"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t(_vm.memberKycDetail.date_of_birth.textKey)))]),_c('div',{staticClass:"input-group date-time-input"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(("MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_" + (_vm.memberKycDetail.date_of_birth.field_key.toUpperCase()))),"rules":_vm.getRules(_vm.memberKycDetail)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"date-time-inline"},[_c('datetime',{attrs:{"disabled":_vm.getDisable(),"type":"datetime","format":_vm.dateTimePickerFormat,"input-class":"form-control"},model:{value:(_vm.memberKycDetail.date_of_birth.value),callback:function ($$v) {_vm.$set(_vm.memberKycDetail.date_of_birth, "value", $$v)},expression:"memberKycDetail.date_of_birth.value"}}),_c('div',{staticClass:"input-group-append"},[(!_vm.getDisable())?_c('span',{staticClass:"input-group-text",on:{"click":function($event){return _vm.clearFromDate()}}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e()])],1),_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),(_vm.memberKycDetail.company_name && _vm.accountType.value !== 'Individual')?_c('CCol',{staticClass:"mt-3 mb-3",attrs:{"lg":"12","md":"12"}},[_vm._v(" "+_vm._s(_vm.$t("MEMBER_DETAIL_COMPONENT_KYC_COMPANY_INFO"))+" ")]):_vm._e(),(_vm.memberKycDetail.company_name && _vm.accountType.value !== 'Individual')?_c('CCol',{staticClass:"form-group",attrs:{"lg":"6","md":"6"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t(_vm.memberKycDetail.company_name.textKey)))]),_c('ValidationProvider',{attrs:{"name":_vm.$t(("MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_" + (_vm.memberKycDetail.company_name.field_key.toUpperCase()))),"rules":_vm.getRules(_vm.memberKycDetail.company_name)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.memberKycDetail.company_name.value),expression:"memberKycDetail.company_name.value"}],staticClass:"form-control text-bold",attrs:{"type":"text","disabled":_vm.getDisable()},domProps:{"value":(_vm.memberKycDetail.company_name.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.memberKycDetail.company_name, "value", $event.target.value)}}}),_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('CCol',{staticClass:"form-group",attrs:{"lg":"6","md":"6"}},[(_vm.memberKycDetail.phone)?_c('div',[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t(_vm.memberKycDetail.phone.textKey)))]),_c('ValidationProvider',{attrs:{"name":_vm.$t(("MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_" + (_vm.memberKycDetail.phone.field_key.toUpperCase()))),"rules":_vm.getRules(_vm.memberKycDetail.phone)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.memberKycDetail.phone.value),expression:"memberKycDetail.phone.value"}],staticClass:"form-control text-bold",attrs:{"type":"text","disabled":_vm.getDisable()},domProps:{"value":(_vm.memberKycDetail.phone.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.memberKycDetail.phone, "value", $event.target.value)}}}),_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()]),(_vm.memberKycDetail.address)?_c('CCol',{staticClass:"form-group",attrs:{"lg":"12","md":"12"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t(_vm.memberKycDetail.address.textKey)))]),_c('ValidationProvider',{attrs:{"name":_vm.$t(("MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_" + (_vm.memberKycDetail.address.field_key.toUpperCase()))),"rules":_vm.getRules(_vm.memberKycDetail.address)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.memberKycDetail.address.value),expression:"memberKycDetail.address.value"}],staticClass:"form-control text-bold",attrs:{"type":"text","disabled":_vm.getDisable()},domProps:{"value":(_vm.memberKycDetail.address.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.memberKycDetail.address, "value", $event.target.value)}}}),_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.memberKycDetail.country)?_c('CCol',{staticClass:"form-group",attrs:{"lg":"6","md":"6"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t(_vm.memberKycDetail.country.textKey)))]),_c('ValidationProvider',{attrs:{"name":_vm.$t(("MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_" + (_vm.memberKycDetail.country.field_key.toUpperCase()))),"rules":_vm.getRules(_vm.memberKycDetail.country)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CSelect',{staticClass:"country-drop",attrs:{"value":_vm.memberKycDetail.country.value,"options":_vm.listCountryOfKyc,"disabled":_vm.getDisable()},on:{"update:value":function($event){return _vm.$set(_vm.memberKycDetail.country, "value", $event)}}}),_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.memberKycDetail.city)?_c('CCol',{staticClass:"form-group",attrs:{"lg":"6","md":"6"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t(_vm.memberKycDetail.city.textKey)))]),_c('ValidationProvider',{attrs:{"name":_vm.$t(("MEMBER_DETAIL_COMPONENT_FIELD_NAME_KYC_VALIDATE_" + (_vm.memberKycDetail.city.field_key.toUpperCase()))),"rules":_vm.getRules(_vm.memberKycDetail.city)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.memberKycDetail.country.value === 'JP')?_c('CSelect',{staticClass:"country-drop",attrs:{"options":_vm.listProvicesOfJp,"disabled":_vm.getDisable(),"value":_vm.memberKycDetail.city.value},on:{"update:value":function($event){return _vm.$set(_vm.memberKycDetail.city, "value", $event)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.memberKycDetail.city.value),expression:"memberKycDetail.city.value"}],staticClass:"form-control text-bold",attrs:{"type":"text","disabled":_vm.getDisable()},domProps:{"value":(_vm.memberKycDetail.city.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.memberKycDetail.city, "value", $event.target.value)}}}),_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1)],1),_c('CCol',{attrs:{"md":"4"}},[_c('CRow',[_c('CCol',{attrs:{"lg":"10","md":"10"}}),_c('CCol',{staticClass:"edit-area",attrs:{"lg":"2","md":"2"}},[(_vm.enabledEditKyc)?_c('div',{staticClass:"edit-member-btn",on:{"click":function($event){return _vm.toggleShowEdit()}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-edit-user.svg"),"alt":""}})]):_vm._e()])],1),_c('CRow',[(_vm.documentList.length > 0)?_c('CCol',{staticClass:"imgList",attrs:{"lg":"12","md":"12"}},[_c('ListImage',{attrs:{"listImgs":_vm.documentList}})],1):_vm._e()],1)],1)],1):_vm._e()],1)]}}])}),_c('StakingModal',{attrs:{"linkTo":_vm.linkTo,"warningLeavePage":_vm.warningLeavePage},on:{"close-modal":_vm.closeModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }