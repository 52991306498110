import _ from 'lodash';
import moment from 'moment';

const numOfSecondsPerOneHour = 60 * 60;
const numOfSecondsPerOneDay = 60 * 60 * 24;
const numOfSecondsPerOneWeek = numOfSecondsPerOneDay * 7;
const numOfSecondsPerOneMonth = numOfSecondsPerOneDay * 30;

export const dateTimeHelper = {
  convertDate(date) {
    if (!date) {
      return date;
    }

    const result = moment(date).toDate();

    return result;
  },
  adjustToDate(toDate) {
    if (!toDate) {
      return toDate;
    }

    const result = moment(toDate).add(1, 'days').toDate();

    return result;
  },
  startOfDate(date) {
    if (!date) {
      return date;
    }

    const result = moment(date).startOf('date').toDate();

    return result;
  },
  convertToDuration(seconds, duration) {
    const result = 0;

    switch (duration) {
      case 'hours':
        return seconds / numOfSecondsPerOneHour;

      case 'days':
        return seconds / numOfSecondsPerOneDay;

      case 'weeks':
        return seconds / numOfSecondsPerOneWeek;

      case 'months':
        return seconds / numOfSecondsPerOneMonth;
    }

    return result;
  },
  convertToSeconds(amount, duration) {
    switch (duration) {
      case 'hours':
        return amount * numOfSecondsPerOneHour;

      case 'days':
        return amount * numOfSecondsPerOneDay;

      case 'weeks':
        return amount * numOfSecondsPerOneWeek;

      case 'months':
        return amount * numOfSecondsPerOneMonth;
    }

    return 0;
  },
  getDurationTime(start_date, end_date) {
    if (!start_date || !end_date) {
      return null;
    }

    const startDate = Date.parse(start_date) / 1000;
    const endDate = Date.parse(end_date) / 1000;
    const secondDurations = endDate - startDate;

    const d = Math.floor(secondDurations / (3600 * 24));
    const h = Math.floor(secondDurations % (3600 * 24) / 3600);
    const m = Math.floor(secondDurations % 3600 / 60);
    const result = [];

    if (d > 0) {
      result.push(d + ' day' + (d > 1 ? 's' : ''));
    }
    if (h > 0) {
      result.push(h + ' hour' + (h > 1 ? 's' : ''));
    }
    if (m > 0) {
      result.push(m + ' minute' + (m > 1 ? 's' : ''));
    }

    return result.join(' ');
  },

};

export default dateTimeHelper;
