<template>
  <div>
    <div v-if="listImgs.length > 0">
      <div class="list-upload-title">{{ $t('LIST_IMAGE_COMPONENT_TITLE_UPLOADED') }}</div>
      <div class="img-zoom-container" v-if="isLoaded">
        <img id="myimage" class="trigger" :src="imgZoom" :data-zoom="imgZoom" />
            <div id="myresult" class="zoom"></div>
        <div class="thumbImg">
          <div v-for="(image, i) in listImgs" :key="i">
            <img :ref="'img' + i" :src="image.url" @click="changeImg(image.url, i)" width="50" height="50" />
          </div>
        </div>
        <!-- <div @click="downloadImage(image.note, image.value)" class="image-name">{{ image.note }}</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { responseHelper, imageHelper } from '@/utils';
import Drift from 'drift-zoom';
export default {
  props: {
    listImgs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      index: null,
      isLoaded: false,
      latestKey: null,
      imgZoom: '',
    };
  },
  components: {},
  computed: {
    linkImgs() {
      return this.listImgs.map((el) => el.url);
    },
  },
  created() {
    this.unwatch = this.$store.watch(
      (state, getters) => this.listImgs,
      (newValue, oldValue) => {
        const listImgs = newValue || [];

        this.convertImages(listImgs);
      },
    );

    this.convertImages(this.listImgs);
  },
  beforeDestroy() {
    this.unwatch();
  },
  mounted() {
    if (this.listImgs.length > 0) {
      this.imgZoom = this.listImgs[0].url;
      var trigger = document.querySelector('.trigger');
      var paneContainer = document.querySelector('.zoom');

      new Drift(trigger, {
        paneContainer: paneContainer,
        inlinePane: false,
        hoverBoundingBox: true,
      });
      this.$refs['img' + 0][0].className = 'active';
    }
  },
  methods: {
    changeImg(imgUrl, i) {
      this.imgZoom = imgUrl;
      for (let i = 0; i < this.listImgs.length; i++) {
        this.$refs['img' + i][0].className = '';
      }
      this.$refs['img' + i][0].className = 'active';
    },
    async convertImages() {
      const key = this.listImgs
        .filter((item) => !!item.value)
        .map((item) => item.id)
        .join(',');

      if (!!key && this.latestKey === key) {
        return;
      }

      this.latestKey = key;
      this.listImgs.forEach(async (el) => {
        const imageUrl = el.value || '';
        if (imageUrl.toUpperCase().endsWith('HEIC')) {
          await this.convertHeicImage(el, imageUrl);
          return;
        }

        this.$set(el, 'url', imageUrl);
      });

      this.isLoaded = true;
    },
    async convertHeicImage(el, link) {
      const url = await imageHelper.convertHeicToUrl(link);
      if (url) {
        this.$set(el, 'url', url);
      } else {
        this.$set(el, 'url', '');
      }
    },
    async downloadImage(name, link) {
      try {
        const file = await this.$http.get(link, { responseType: 'blob' });
        const fileAsBlob = new Blob([file]);
        saveAs(fileAsBlob, name);
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('LIST_IMAGE_COMPONENT_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>

<style lang="scss">
.zoom {
  position: absolute;
  top: 0px;
  left: 95%;
  width: 350px;
  height: 350px;
}
.thumbImg {
  display: flex;
  flex-wrap: wrap;
}
#myimage {
  width: 90%;
}
.thumbImg > div {
  padding-left: 5px;
  padding-top: 10px;
}
.thumbImg > div > img {
  border: 1px solid #c0c0c0;
}
.img-zoom .active {
  box-shadow: 1px 1px 5px #2b7ef1;
}
</style>
