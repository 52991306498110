<template>
  <CModal
    class="staking-confirmation-modal reject-order-modal"
    :show="isShownInsufficientModal"
    :centered="true"
  >
    <template #header>
      <CButtonClose class="close-modal" @click="closeModal" />
    </template>
    <div>
      <div class="tit-dele">
        <h4
          v-if="stepOnInsufficientModal === 1"
        >{{$t('MODAL_INSUFFICIENT_COMPONENT_TITLE_REASON_FOR_INFFICIENT_STATUS')}}</h4>
        <h4
          v-if="stepOnInsufficientModal === 2"
        >{{$t('MODAL_INSUFFICIENT_COMPONENT_TITLE_CONFIRM_FOR_INFFICIENT_STATUS')}}</h4>
      </div>

      <div class="text-modal">
        <ValidationObserver
          v-if="stepOnInsufficientModal === 1"
          ref="insufficientForm"
          v-slot="{ handleSubmit }"
        >
          <CForm @submit.prevent="handleSubmit(insufficientKycStatus)">
            <ValidationProvider
              name="MODAL_INSUFFICIENT_COMPONENT_FIELD_NAME_VALIDATE_OPTION"
              rules="required"
            >
              <CRow class="form-group" slot-scope="{ errors}">
                <CCol lg="12" md="12">
                  <div
                    role="group"
                    class="form-check"
                    v-for="(option, index) in insufficientOptions"
                    :key="index"
                  >
                    <input
                      :id="`insufficient-${index}`"
                      type="radio"
                      name="reasonOption"
                      class="form-check-input"
                      :value="option.templateName"
                      v-model="reasonOption"
                    />
                    <label
                      :for="`insufficient-${index}`"
                      class="form-check-label"
                    >{{option.subject}}</label>
                    <div class="text-truncate form-text text-muted" v-html="option.description"></div>
                  </div>

                  <span class="error-msg-wrap">
                    <span class="error-msg">{{errors[0]}}</span>
                  </span>
                </CCol>
              </CRow>
            </ValidationProvider>
            <ValidationProvider
              name="MODAL_INSUFFICIENT_COMPONENT_FIELD_NAME_VALIDATE_REASON"
              :rules="`${reasonOption == OTHER_OPTION ? 'required': '' }|max:1000`"
            >
              <CRow class="form-group" slot-scope="{ errors}">
                <CCol lg="12" md="12">
                  <textarea
                    type="text"
                    class="form-control"
                    :disabled="reasonOption != OTHER_OPTION"
                    v-model="comments"
                    rows="5"
                  />
                  <span class="error-msg-wrap">
                    <span class="error-msg">{{errors[0]}}</span>
                  </span>
                </CCol>
              </CRow>
            </ValidationProvider>
          </CForm>
        </ValidationObserver>

        <div v-if="stepOnInsufficientModal === 2">
          <div class="reason-option">{{reasonDetails.subject}}</div>
          <div class="reason-description" v-html="reasonDetails.description2"></div>
        </div>
      </div>
    </div>
    <template #footer>
      <CButton
        v-if="stepOnInsufficientModal === 1"
        class="btn-cancel"
        @click="closeModal"
        color="dark"
        variant="outline"
      >{{$t("MODAL_INSUFFICIENT_COMPONENT_BUTTON_CANCEL")}}</CButton>

      <CButton
        v-if="stepOnInsufficientModal === 1"
        @click="showConfirmInsufficientForm"
        :disabled="!reasonOption"
        class="btn-ok"
      >{{$t('MODAL_INSUFFICIENT_COMPONENT_BUTTON_INSUFFICIENT')}}</CButton>

      <CButton
        v-if="stepOnInsufficientModal === 2"
        class="btn-cancel"
        @click="backOnStep"
        color="dark"
        variant="outline"
      >{{$t('MODAL_INSUFFICIENT_COMPONENT_BUTTON_BACK')}}</CButton>

      <CButton
        v-if="stepOnInsufficientModal === 2"
        @click="insufficientKycStatus"
        class="btn-ok"
      >{{$t('MODAL_INSUFFICIENT_COMPONENT_BUTTON_CONFIRM_INSUFFICIENT')}}</CButton>
    </template>
  </CModal>
</template>

<script>
const OTHER_OPTION = '___OTHER___';
import responseHelper from '@/utils/response-helper';
import endpoints from '@/constants/endpoints';
import _ from 'lodash';
import stringHelper from '@/utils/string-helper';
import moment from 'moment';
import { Constants, env } from '@/constants';

export default {
  name: 'ModalInsufficient',
  props: {
    isShownInsufficientModal: {
      type: Boolean,
      default: false,
    },
    member: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return {
      OTHER_OPTION: OTHER_OPTION,
      isProcessing: false,
      reasonOption: null,
      insufficientOptions: [],
      comments: null,
      stepOnInsufficientModal: 1,
    };
  },
  computed: {
    reasonDetails() {
      const option = this.insufficientOptions.find(x => x.templateName === this.reasonOption);
      const result = option || {};

      if (this.reasonOption === OTHER_OPTION) {
        result.description2 = stringHelper.createMarkupWithNewLine(this.comments);
      } else {
        result.description2 = result.description;
      }

      return result;
    },
  },
  async mounted() {
    const memberId = this.$route.params.memberId;
    this.getRejectOptions();
  },
  methods: {
    async getRejectOptions() {
      try {
        const res = await this.$http.get(endpoints.getRejectOptions('CHILDPOOL_KYC_INSUFFICIENT'));
        const items = _.sortBy(res.data, 'display_order');
        const insufficientOptions = items
          .filter(item => item.language === 'en')
          .map(item => {
            return {
              templateName: item.name,
              subject: item.option_name,
              description: item.subject,
            };
          });
        insufficientOptions.push({
          templateName: OTHER_OPTION,
          subject: this.$t('MODAL_INSUFFICIENT_COMPONENT_TEXT_SUBJECT_OTHER'),
          description: null,
        });

        this.insufficientOptions = insufficientOptions;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MODAL_INSUFFICIENT_COMPONENT_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },

    async insufficientKycStatus() {
      if (this.isProcessing) {
        return;
      }

      this.isProcessing = true;
      try {
        const data = {
          status: 'INSUFFICIENT',
        };
        if (this.reasonOption === OTHER_OPTION) {
          data.note = this.comments;
        } else {
          data.template = this.reasonOption;
        }

        var res = await this.$http.put(endpoints.updateMemberKyc(this.member.id, this.member.kyc_id), data);
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('MODAL_INSUFFICIENT_COMPONENT_TITLE_NOTIFY_SUCCESS'),
          text: this.$t('MODAL_INSUFFICIENT_COMPONENT_TEXT_NOTIFY_SUCCESS'),
          duration: 1000,
        });
        this.isProcessing = false;
        this.$emit('setMemberKycStatus', res.data);
        this.closeModal();
      } catch (err) {
        this.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MODAL_INSUFFICIENT_COMPONENT_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async showConfirmInsufficientForm() {
      const isValid = await this.$refs.insufficientForm.validate();
      if (!isValid) {
        return;
      }
      this.nextStepOnInsufficientModal();
    },
    closeModal() {
      this.$emit('closeInsufficientModal');
    },
    nextStepOnInsufficientModal() {
      this.stepOnInsufficientModal = 2;
    },
    backOnStep() {
      this.stepOnInsufficientModal = 1;
    },
  },
};
</script>
